define(['app'], (app) => {

  return () => {
    const component = {};

    component.config = {
      selectors: {
        backToProductButton: '.subscribeAndSaveInformationModal_button',
      },
      channels: {
        closeModal: 'modal/close'
      }
    };

    component.init = (element) => {
      component.element = element;
      component.backToProduct = component.element.querySelector(component.config.selectors.backToProductButton);

      component.bind();
    };

    component.bind = () => {
      component.backToProduct && component.backToProduct.addEventListener('click', component.backToProductButtonClicked);
    };

    component.backToProductButtonClicked = () => {
      app.publish(component.config.channels.closeModal);
    };

    return component;
  };
});
